import {
    Calculate as CalculateIcon,
    FileDownload as FileDownloadIcon,
    InfoOutlined as InfoOutlinedIcon,
    MoreVert as MoreVertIcon,
    Print as PrintIcon,
    Visibility as VisibilityIcon,
} from "@mui/icons-material";
import {
    Button,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import React, { useRef, useState } from "react";

import {
    DisciplineCategoryId,
    EventFormat,
    Order,
    callEventFormatFunction,
    formatCertType,
    formatClassLevelRange,
    getDisciplineCategory,
    getEventFormatObject,
} from "@bujus/common";
import { useApiServiceContext, useDialogDisplayer } from "@bujus/common-frontend";

import { DataMaster } from "@/base";
import { useOpenInstructionsDrawerContext, useSelectedEventStateContext } from "@/contexts";
import { getResultColor } from "@/utilities";

import { AllCertsExportingDialog } from "./all-certs-exporting-dialog";
import { AllCertsGenerationDialog } from "./all-certs-generation-dialog";
import { AllCertsPrintingDialog } from "./all-certs-printing-dialog";
import { CertDetailsDialog } from "./cert-details-dialog/cert-details-dialog";
import { CertPrintDialog } from "./cert-printing-dialog";

const RightDataMasterToolbarComponents = ({
    onAllExportingMenuItemClick,
    onAllPrintButtonClick,
    onAllGenerationButtonClick,
    query,
}) => {
    const openInstructionsDrawer = useOpenInstructionsDrawerContext();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const menuAnchorElementRef = useRef();

    const handleAllGenerationButtonClick = () => {
        onAllGenerationButtonClick();
    };

    const handleAllPrintButtonClick = () => {
        onAllPrintButtonClick();
    };

    const handleMenuOpenButtonClick = () => {
        setIsMenuOpen(true);
    };

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };

    const handleAllExportingMenuItemClick = () => {
        onAllExportingMenuItemClick();
    };

    const handleInstructionsButtonClick = () => {
        openInstructionsDrawer("/auswertung/urkunden-generieren");
    };

    return (
        <>
            <Button onClick={handleAllGenerationButtonClick} startIcon={<CalculateIcon />}>
                Generieren
            </Button>
            <Button
                disabled={query.isFetching || query.data === undefined || query.data.count === 0}
                onClick={handleAllPrintButtonClick}
                startIcon={<PrintIcon />}
            >
                Drucken
            </Button>
            <IconButton ref={menuAnchorElementRef} onClick={handleMenuOpenButtonClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={menuAnchorElementRef.current}
                onClose={handleMenuClose}
                open={isMenuOpen}
            >
                {/* <MenuItem
                    disabled={
                        query.isFetching || query.data === undefined || query.data.count === 0
                    }
                    onClick={handleAllExportingMenuItemClick}
                >
                    <ListItemIcon>
                        <FileDownloadIcon />
                    </ListItemIcon>
                    <ListItemText>Übersicht Drucken</ListItemText>
                </MenuItem> */}
                <MenuItem
                    disabled={
                        query.isFetching || query.data === undefined || query.data.count === 0
                    }
                    onClick={handleAllExportingMenuItemClick}
                >
                    <ListItemIcon>
                        <FileDownloadIcon />
                    </ListItemIcon>
                    <ListItemText>Exportieren</ListItemText>
                </MenuItem>
            </Menu>
            <IconButton onClick={handleInstructionsButtonClick}>
                <InfoOutlinedIcon />
            </IconButton>
        </>
    );
};

const CertsPage__GeneratedTab = () => {
    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const allGenerationDialogDisplayer = useDialogDisplayer(AllCertsGenerationDialog);
    const allPrintDialogDisplayer = useDialogDisplayer(AllCertsPrintingDialog);
    const allExportingDialogDisplayer = useDialogDisplayer(AllCertsExportingDialog);
    const detailsDialogDisplayer = useDialogDisplayer(CertDetailsDialog);
    const printDialogDisplayer = useDialogDisplayer(CertPrintDialog);
    const dialogDisplayers = [
        allGenerationDialogDisplayer,
        allPrintDialogDisplayer,
        allExportingDialogDisplayer,
        detailsDialogDisplayer,
        printDialogDisplayer,
    ];

    const handleDataMasterDetailsButtonClick = (cert) => {
        detailsDialogDisplayer.open({
            cert,
            printDialogDisplayer,
        });
    };

    const handleDataMasterPrintButtonClick = (cert) => {
        printDialogDisplayer.open({ cert });
    };

    const dataMasterColumns = [
        {
            field: "participant.schoolInternalId",
            valueGetter: ({ row }) => row.participant.schoolInternalId,
            type: "string",
            headerName: "T Schulinterne-ID",
            width: 150,
        },
        {
            field: "participant.name.first",
            valueGetter: ({ row }) => row.participant.name.first,
            type: "string",
            headerName: "Teilnehmer Vorname",
            width: 200,
        },
        {
            field: "participant.name.last",
            valueGetter: ({ row }) => row.participant.name.last,
            type: "string",
            headerName: "Teilnehmer Nachname",
            width: 200,
        },
        {
            field: "participant.class",
            valueGetter: ({ row }) => row.participant.class,
            type: "string",
            headerName: "T Klasse",
            width: 100,
        },
        {
            field: "participant.group.name",
            valueGetter: ({ row }) =>
                row.participant.group === undefined ? undefined : row.participant.group.name,
            type: "string",
            headerName: "T Riege",
            width: 100,
        },
        ...(selectedEvent.format !== EventFormat.CONTEST
            ? []
            : [
                  {
                      field: "participant.group.classLevelRange",
                      valueGetter: ({ row }) =>
                          row.participant.group === undefined
                              ? undefined
                              : row.participant.group.classLevelRange,
                      valueFormatter: ({ value }) =>
                          value === undefined ? undefined : formatClassLevelRange(value),
                      type: "string",
                      headerName: "T Klassenstufe",
                      sortable: false,
                      width: 125,
                  },
              ]),
        {
            field: "points",
            type: "number",
            valueFormatter: ({ value }) => value,
            headerName: getEventFormatObject("Rang", "Punkte", selectedEvent.format),
            width: 75,
        },
        {
            field: "type",
            type: "string",
            valueFormatter: ({ value }) => formatCertType(value),
            headerName: "Typ",
            width: 150,
        },
        ...callEventFormatFunction(
            () => [
                ...Object.values(DisciplineCategoryId).map((x) => ({
                    field: `disciplineCategoryIdToDisciplineCategoryEvaluationMap.${x}.rank`,
                    type: "number",
                    valueGetter: ({ row }) =>
                        row.disciplineCategoryIdToDisciplineCategoryEvaluationMap[x].rank,
                    headerName: `${getDisciplineCategory(selectedEvent.format, x).name} Rang`,
                    sortable: false,
                    width: 100,
                })),
                {
                    field: "rankSum",
                    type: "number",
                    headerName: "Rang Summe",
                    sortable: false,
                    width: 100,
                },
                {
                    field: "groupParticipantCount",
                    type: "number",
                    headerName: "Riege T Anzahl",
                    sortable: false,
                    width: 125,
                },
            ],
            () =>
                Object.values(DisciplineCategoryId).map((x) => ({
                    field: `disciplineCategoryIdToDisciplineCategoryEvaluationMap.${x}.points`,
                    type: "number",
                    renderCell: ({ row }) =>
                        !row.disciplineCategoryIdToDisciplineCategoryEvaluationMap[x]
                            .isValid ? undefined : (
                            <Typography
                                color={getResultColor(
                                    row.disciplineCategoryIdToDisciplineCategoryEvaluationMap,
                                    row.disciplineCategoryIdToDisciplineCategoryEvaluationMap[x],
                                )}
                            >
                                {
                                    row.disciplineCategoryIdToDisciplineCategoryEvaluationMap[x]
                                        .points
                                }
                            </Typography>
                        ),
                    headerName: `${getDisciplineCategory(selectedEvent.format, x).name} Punkte`,
                    sortable: false,
                    width: 175,
                })),
            selectedEvent.format,
        ),
        {
            field: "actions",
            getActions: ({ row }) => [
                <Tooltip title="Details">
                    <GridActionsCellItem
                        icon={<VisibilityIcon />}
                        label="Details"
                        onClick={() => handleDataMasterDetailsButtonClick(row)}
                    />
                </Tooltip>,
                <Tooltip title="Drucken">
                    <GridActionsCellItem
                        icon={<PrintIcon />}
                        label="Drucken"
                        onClick={() => handleDataMasterPrintButtonClick(row)}
                    />
                </Tooltip>,
            ],
            type: "actions",
            headerName: "Aktionen",
            sortable: false,
            width: 100,
        },
    ];

    const handleDataMasterListItemsAsync = async (listingModifiers) =>
        apiService.certs.listOnlyGeneratedAsync(selectedEvent.id, listingModifiers);

    const handleDataMasterAllExportingMenuItemClick = () => {
        allExportingDialogDisplayer.open();
    };

    const handleDataMasterAllGenerationButtonClick = () => {
        allGenerationDialogDisplayer.open();
    };

    const handleDataMasterAllPrintButtonClick = () => {
        allPrintDialogDisplayer.open();
    };

    return (
        <>
            <DataMaster
                baseQueryKey={["certs", "list", selectedEvent.id, "isOnlyGenerated"]}
                columns={dataMasterColumns}
                defaultFieldSorting={{
                    path: "participant.name.last",
                    order: Order.ASCENDING,
                }}
                dialogDisplayers={dialogDisplayers}
                exampleSearchDescription="Teilnehmer, Punkte, Typ"
                fullSearchDescription="Durchsucht Teilnehmer (Schulinterne-ID, Vorname, Nachname), Punkte, Typ"
                getKeyFromItem={(item) => item.id}
                listItemsAsync={handleDataMasterListItemsAsync}
                noFilteredItemsMessage="Keine Urkunden gefunden."
                noItemsMessage="Noch keine Urkunden generiert."
                RightToolbarComponents={RightDataMasterToolbarComponents}
                rightToolbarComponentsProps={{
                    onAllExportingMenuItemClick: handleDataMasterAllExportingMenuItemClick,
                    onAllGenerationButtonClick: handleDataMasterAllGenerationButtonClick,
                    onAllPrintButtonClick: handleDataMasterAllPrintButtonClick,
                }}
            />
            {allGenerationDialogDisplayer.element}
            {allPrintDialogDisplayer.element}
            {allExportingDialogDisplayer.element}
            {detailsDialogDisplayer.element}
            {printDialogDisplayer.element}
        </>
    );
};

export { CertsPage__GeneratedTab };
