import { deDE as muiDeDE } from "@mui/material/locale";
import { createTheme } from "@mui/material/styles";
import { deDE as dataGridLocale } from "@mui/x-data-grid";
import { deDE as datePickersLocale } from "@mui/x-date-pickers";

import { colors } from "@bujus/common";

import { gradients } from "./gradients";

const addOpacityToHexColor = (hex, opacity) =>
    hex +
    Math.round(opacity * 255)
        .toString(16)
        .padStart(2, "0");

const theme = createTheme(
    {
        // Standard breakpoints, but changed xs from 0 to 320
        breakpoints: {
            values: {
                xs: 320,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
                xxl: 1920, // Custom
            },
        },
        palette: {
            common: {
                white: colors.white,
                black: colors.black,
            },
            background: {
                default: colors.grayShades[0],
                // default: colors.white,
                paper: colors.white,
                darkOverlay: `${colors.black}dd`, // Custom,
                primary: colors.amaranthShades[50], // Custom
            },
            text: {
                primary: colors.black,
                secondary: colors.grayBlack,
                onDark: colors.white, // Custom
            },
            primary: {
                light: colors.amaranthShades[400],
                main: colors.amaranthShades[500],
                dark: colors.amaranthShades[600],
                contrastText: colors.white,
            },
            secondary: {
                light: colors.grayBlack,
                main: colors.grayBlack,
                dark: colors.grayBlack,
                contrastText: colors.white,
            },
            error: {
                light: colors.redShades[400],
                main: colors.redShades[500],
                dark: colors.redShades[600],
                contrastText: colors.white,
            },
            warning: {
                light: colors.orangeShades[400],
                main: colors.orangeShades[500],
                dark: colors.orangeShades[600],
                contrastText: colors.white,
            },
            success: {
                light: colors.greenShades[400],
                main: colors.greenShades[500],
                dark: colors.greenShades[600],
                contrastText: colors.white,
            },
            info: {
                light: colors.blueShades[400],
                main: colors.blueShades[500],
                dark: colors.blueShades[600],
                contrastText: colors.white,
            },
            selection: {
                background: addOpacityToHexColor(colors.amaranthShades[500], 0.1),
                border: addOpacityToHexColor(colors.amaranthShades[500], 0.7),
            },
        },
        typography: {
            fontFamily: "Lato, sans-serif",
            dialogContentText: { display: "block" },
            dialogContentTextLong: {
                display: "block",
                wordBreak: "break-all",
            },
        },
        components: {
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        backgroundColor: colors.amaranthShades[50],
                        color: colors.amaranthShades[600],
                    },
                },
            },
            MuiCard: { defaultProps: { variant: "outlined" } },
            MuiCardContent: { styleOverrides: { root: { "&:last-child": { paddingBottom: 16 } } } },
            MuiCheckbox: { defaultProps: { color: "primary" } },
            MuiDialog: {
                defaultProps: {
                    fullWidth: true,
                    maxWidth: "sm",
                },
                styleOverrides: { root: { minWidth: "900px" } }, // md breakpoint
            },
            MuiIconButton: { defaultProps: { color: "primary" } },
            MuiTabs: { defaultProps: { variant: "fullWidth" } },
            MuiTypography: {
                variants: [
                    {
                        props: { color: "main-gradient" },
                        style: {
                            backgroundImage: gradients.main,
                            backgroundClip: "text",
                            color: colors.amaranthShades[500],
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                        },
                    },
                ],
            },
        },
        zIndex: {
            // appBar: 1100
            // drawer: 1200
            // fab: 1050
            // mobileStepper: 1000
            // modal: 1300
            // snackbar: 1400
            // speedDial: 1050
            // tooltip: 1500
            appBar: 1250,
            overlay: 1550,
        },
    },
    muiDeDE,
    dataGridLocale,
    datePickersLocale,
);

export { theme };
