import { Print as PrintIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React from "react";

import { formatCertType } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { DialogClosingButton } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

const TestCertPrintDialog = ({ certType, close, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const mutation = useMutation(
        async (variables) =>
            apiService.certTemplateSets.printTestAsync(variables.eventId, variables.certType),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (data, variables) => {
                snackbarsController.enqueueSuccessMessage(
                    `${formatCertType(variables.certType)} - Test Urkunde gedruckt`,
                );
                snackbarsController.enqueuePopUpWarning();
                close();
                window.open(data, "_blank");
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    const handleButtonClick = () => {
        mutation.mutate({
            eventId: selectedEvent.id,
            certType,
        });
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <DialogTitle>{formatCertType(certType)}-Test drucken</DialogTitle>
            <DialogClosingButton
                isDisabled={mutation.isLoading}
                onClick={handleClosingButtonClick}
            />
            <DialogActions>
                <LoadingButton
                    loading={mutation.isLoading}
                    onClick={handleButtonClick}
                    startIcon={<PrintIcon />}
                >
                    Drucken
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export { TestCertPrintDialog };
