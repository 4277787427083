import {
    ArrowForward as ArrowForwardIcon,
    Calculate as CalculateIcon,
    Download as DownloadIcon,
} from "@mui/icons-material";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
    timelineOppositeContentClasses,
} from "@mui/lab";
import { Box, Button, Card, CardContent, FormLabel, Typography } from "@mui/material";
import { nanoid } from "nanoid";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import { useForm as useFormController, useWatch } from "react-hook-form";

import {
    DisciplineCategoryId,
    Gender,
    calculateAgeGroup,
    certConvert,
    certsGenerationService,
    eventFormatToAgeGroupsMap,
    eventFormatToAllDisciplinesMap,
    eventFormatToDisciplineCategoriesMap,
    formatGender,
    generateDownloadFileName,
    getDiscipline,
    getDisciplineCategoryOfDiscipline,
    getEventFormatObject,
    participantConvert,
    resultConvert,
} from "@bujus/common";

import { CardCollapse, Checkbox, CsvDataTable, Form, RadioSelect, Select } from "@/base";
import { config } from "@/config";
import { useSelectedEventStateContext } from "@/contexts";
import {
    StatisticsGrouping,
    StatisticsType,
    formatStatisticsGrouping,
    formatStatisticsType,
} from "@/enums";
import { downloadCsvFile, generateHydratedCerts, generateHydratedCertsResult } from "@/utilities";

const StatisticsPage__LeaderboardTab = ({ groups, options, participants, results }) => {
    const [selectedEvent] = useSelectedEventStateContext();

    const [fullTypeString, setFullTypeString] = useState();
    const [visibleCsvColumns, setVisibleCsvColumns] = useState();
    const [csvRows, setCsvRows] = useState();

    const formController = useFormController({
        mode: "onChange",
        defaultValues: {
            type: StatisticsType.CERT,
            disciplineCategory: eventFormatToDisciplineCategoriesMap[selectedEvent.format][0],
            discipline: eventFormatToAllDisciplinesMap[selectedEvent.format][0],
            genders: Object.values(Gender).reduce(
                (xAccumulator, x) => ({
                    ...xAccumulator,
                    [x]: true,
                }),
                {},
            ),
            ageGroups: eventFormatToAgeGroupsMap[selectedEvent.format].reduce(
                (xAccumulator, x) => ({
                    ...xAccumulator,
                    [x]: true,
                }),
                {},
            ),
            grouping: StatisticsGrouping.GENDER_AND_AGE_GROUP,
            rankLimit: 3,
        },
    });
    const typeFieldValue = useWatch({
        control: formController.control,
        name: "type",
    });

    const rankLimitOptions = [
        1,
        2,
        3,
        4,
        5,
        10,
        15,
        20,
        25,
        50,
        config.event.alltimeLimits.participant,
    ];

    const handleFormSubmit = (data) => {
        formController.reset(data);
        let newFullTypeString = formatStatisticsType(typeFieldValue);
        switch (typeFieldValue) {
            case StatisticsType.DISCIPLINE_CATEGORY:
                newFullTypeString += ` ${data.disciplineCategory.name}`;
                break;
            case StatisticsType.DISCIPLINE:
                newFullTypeString += ` ${data.discipline.name}`;
                break;
            case StatisticsType.CERT:
                break;
            default:
                throw new Error(`Invalid statistics type: ${typeFieldValue}`);
        }
        setFullTypeString(newFullTypeString);
        const groupAndSliceItems = (items, sortItems) => {
            const validGenders = Object.entries(data.genders)
                .filter(([, xValue]) => xValue)
                .map(([xKey]) => xKey);
            const validAgeGroups = Object.entries(data.ageGroups)
                .filter(([, xValue]) => xValue)
                .map(([xKey]) => xKey);
            const filteredItems = items.filter(
                (x) =>
                    validGenders.includes(x.participant.gender) &&
                    validAgeGroups.includes(
                        calculateAgeGroup(selectedEvent.start, x.participant.birthYear).toString(),
                    ),
            );
            const sortedItems = filteredItems.sort(sortItems);
            console.log("mid sorted", filteredItems);
            const groupedItems = [];
            switch (data.grouping) {
                case StatisticsGrouping.NONE:
                    if (
                        Object.entries(data.genders).every(([, xValue]) => !xValue) ||
                        Object.entries(data.ageGroups).every(([, xValue]) => !xValue)
                    ) {
                        break;
                    }
                    groupedItems.push({
                        genders: { ...data.genders },
                        ageGroups: { ...data.ageGroups },
                        data: sortedItems.slice(0, data.rankLimit),
                    });
                    break;
                case StatisticsGrouping.GENDER:
                    Object.entries(data.genders)
                        .filter(([, xValue]) => xValue)
                        .forEach(([xKey]) => {
                            groupedItems.push({
                                genders: { [xKey]: true },
                                ageGroups: { ...data.ageGroups },
                                data: sortedItems
                                    .filter((y) => y.participant.gender === xKey)
                                    .slice(0, data.rankLimit),
                            });
                        });
                    break;
                case StatisticsGrouping.AGE_GROUP:
                    Object.entries(data.ageGroups)
                        .filter(([, xValue]) => xValue)
                        .forEach(([xKey]) => {
                            groupedItems.push({
                                genders: { ...data.genders },
                                ageGroups: { [xKey]: true },
                                data: sortedItems
                                    .filter(
                                        (y) =>
                                            calculateAgeGroup(
                                                selectedEvent.start,
                                                y.participant.birthYear,
                                            ).toString() === xKey,
                                    )
                                    .slice(0, data.rankLimit),
                            });
                        });
                    break;
                case StatisticsGrouping.GENDER_AND_AGE_GROUP:
                    Object.entries(data.genders)
                        .filter(([, xValue]) => xValue)
                        .forEach(([xKey]) => {
                            Object.entries(data.ageGroups)
                                .filter(([, yValue]) => yValue)
                                .forEach(([yKey]) => {
                                    groupedItems.push({
                                        genders: { [xKey]: true },
                                        ageGroups: { [yKey]: true },
                                        data: sortedItems
                                            .filter(
                                                (z) =>
                                                    z.participant.gender === xKey &&
                                                    calculateAgeGroup(
                                                        selectedEvent.start,
                                                        z.participant.birthYear,
                                                    ).toString() === yKey,
                                            )
                                            .slice(0, data.rankLimit),
                                    });
                                });
                        });
                    break;
                default:
                    throw new Error(`Invalid statistics grouping: ${data.grouping}`);
            }
            return groupedItems;
        };
        switch (data.type) {
            case StatisticsType.DISCIPLINE_CATEGORY: {
                const filteredResults = results.filter(
                    (x) =>
                        getDisciplineCategoryOfDiscipline(selectedEvent.format, x.disciplineId)
                            .id === data.disciplineCategory.id,
                );
                const certs = certsGenerationService.generateAll(
                    selectedEvent,
                    groups,
                    participants,
                    filteredResults,
                    options,
                );
                let hydratedCerts = generateHydratedCerts(participants, certs);
                hydratedCerts = hydratedCerts.map((x) => {
                    const tempX = x;
                    Object.values(DisciplineCategoryId).forEach((disciplineCategoryId) => {
                        if (
                            tempX.disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                disciplineCategoryId
                            ].isValid
                        ) {
                            tempX.disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                disciplineCategoryId
                            ].bestResult.discipline = getDiscipline(
                                selectedEvent.format,
                                tempX.disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                    disciplineCategoryId
                                ].bestResult.disciplineId,
                            );
                            delete tempX.disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                disciplineCategoryId
                            ].bestResult.disciplineId;
                        }
                    });
                    return tempX;
                });
                const items = hydratedCerts
                    .filter(
                        (x) =>
                            x.disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                data.disciplineCategory.id
                            ].isValid,
                    )
                    .map((x) => ({
                        id: nanoid(),
                        participant: x.participant,
                        extraData:
                            x.disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                data.disciplineCategory.id
                            ],
                    }));
                console.log("before sorted", items);
                const groupedAndSlicedItems = groupAndSliceItems(
                    items,
                    (itemA, itemB) => itemB.extraData.points - itemA.extraData.points,
                );
                console.log("sorted", groupedAndSlicedItems);
                setVisibleCsvColumns([
                    "Geschlecht",
                    "Altersgruppe",
                    "Rang",
                    "Teilnehmer Schulinterne-ID",
                    "Teilnehmer Vorname",
                    "Teilnehmer Nachname",
                    "Ergebnis Disziplin",
                    "Ergebnis Wert",
                ]);
                setCsvRows(
                    groupedAndSlicedItems.flatMap((x) => {
                        const formattedGenders = Object.entries(x.genders)
                            .filter(([, zValue]) => zValue)
                            .map(([zKey]) => formatGender(zKey))
                            .join(", ");
                        const formattedAgeGroups = Object.entries(x.ageGroups)
                            .filter(([, zValue]) => zValue)
                            .map(([zKey]) => zKey)
                            .join(", ");
                        if (x.data.length === 0) {
                            return {
                                Geschlecht: formattedGenders,
                                Altersgruppe: formattedAgeGroups,
                                Rang: undefined,
                                ...participantConvert.toMockupCsvRow(selectedEvent.format, {
                                    isGenderAndBirthYearIncluded: false,
                                    prefix: "Teilnehmer",
                                }),
                                ...resultConvert.toBestResultMockupCsvRow({ prefix: "Ergebnis" }),
                            };
                        }
                        return x.data.map((y, yIndex) => ({
                            Geschlecht: formattedGenders,
                            Altersgruppe: formattedAgeGroups,
                            Rang: yIndex + 1,
                            ...participantConvert.toCsvRow(selectedEvent.format, y.participant, {
                                isGenderAndBirthYearIncluded: false,
                                prefix: "Teilnehmer",
                            }),
                            ...resultConvert.toBestResultCsvRow(
                                selectedEvent.format,
                                y.extraData.bestResult,
                                { prefix: "Ergebnis" },
                            ),
                        }));
                    }),
                );
                break;
            }
            case StatisticsType.DISCIPLINE: {
                const filteredResults = results.filter(
                    (x) => x.disciplineId === data.discipline.id,
                );
                const certs = certsGenerationService.generateAll(
                    selectedEvent,
                    groups,
                    participants,
                    filteredResults,
                    options,
                );
                let hydratedCerts = generateHydratedCerts(participants, certs);
                hydratedCerts = hydratedCerts.map((x) => {
                    const tempX = x;
                    Object.values(DisciplineCategoryId).forEach((disciplineCategoryId) => {
                        if (
                            tempX.disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                disciplineCategoryId
                            ].isValid
                        ) {
                            tempX.disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                disciplineCategoryId
                            ].bestResult.discipline = getDiscipline(
                                selectedEvent.format,
                                tempX.disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                    disciplineCategoryId
                                ].bestResult.disciplineId,
                            );
                            delete tempX.disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                disciplineCategoryId
                            ].bestResult.disciplineId;
                        }
                    });
                    return tempX;
                });
                const disciplineCategoryId = getDisciplineCategoryOfDiscipline(
                    selectedEvent.format,
                    data.discipline.id,
                ).id;
                const items = hydratedCerts
                    .filter(
                        (x) =>
                            x.disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                disciplineCategoryId
                            ].isValid,
                    )
                    .map((x) => ({
                        id: nanoid(),
                        participant: x.participant,
                        extraData:
                            x.disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                disciplineCategoryId
                            ],
                    }));
                const groupedAndSlicedItems = groupAndSliceItems(
                    items,
                    (itemA, itemB) => itemB.extraData.points - itemA.extraData.points,
                );
                setVisibleCsvColumns([
                    "Geschlecht",
                    "Altersgruppe",
                    "Rang",
                    "Teilnehmer Schulinterne-ID",
                    "Teilnehmer Vorname",
                    "Teilnehmer Nachname",
                    "Ergebnis Disziplin",
                    "Ergebnis Wert",
                ]);
                setCsvRows(
                    groupedAndSlicedItems.flatMap((x) => {
                        const formattedGenders = Object.entries(x.genders)
                            .filter(([, zValue]) => zValue)
                            .map(([zKey]) => formatGender(zKey))
                            .join(", ");
                        const formattedAgeGroups = Object.entries(x.ageGroups)
                            .filter(([, zValue]) => zValue)
                            .map(([zKey]) => zKey)
                            .join(", ");
                        if (x.data.length === 0) {
                            return {
                                Geschlecht: formattedGenders,
                                Altersgruppe: formattedAgeGroups,
                                Rang: undefined,
                                ...participantConvert.toMockupCsvRow(selectedEvent.format, {
                                    isGenderAndBirthYearIncluded: false,
                                    prefix: "Teilnehmer",
                                }),
                                ...resultConvert.toBestResultMockupCsvRow({ prefix: "Ergebnis" }),
                            };
                        }
                        return x.data.map((y, yIndex) => ({
                            Geschlecht: formattedGenders,
                            Altersgruppe: formattedAgeGroups,
                            Rang: yIndex + 1,
                            ...participantConvert.toCsvRow(selectedEvent.format, y.participant, {
                                isGenderAndBirthYearIncluded: false,
                                prefix: "Teilnehmer",
                            }),
                            ...resultConvert.toBestResultCsvRow(
                                selectedEvent.format,
                                y.extraData.bestResult,
                                { prefix: "Ergebnis" },
                            ),
                        }));
                    }),
                );
                break;
            }
            case StatisticsType.CERT: {
                const certs = certsGenerationService.generateAll(
                    selectedEvent,
                    groups,
                    participants,
                    results,
                    options,
                );
                const hydratedCertsResult = generateHydratedCertsResult(participants, certs);
                const items = hydratedCertsResult.generatedCerts.map((x) => {
                    const tempX = {
                        id: x.id,
                        participant: x.participant,
                        extraData: x,
                    };
                    delete tempX.extraData.participant;
                    return tempX;
                });
                const groupedAndSlicedItems = groupAndSliceItems(
                    items,
                    (itemA, itemB) => itemB.extraData.points - itemA.extraData.points,
                );
                setVisibleCsvColumns([
                    "Geschlecht",
                    "Altersgruppe",
                    "Rang",
                    "Teilnehmer Schulinterne-ID",
                    "Teilnehmer Vorname",
                    "Teilnehmer Nachname",
                    "Urkunde Punkte",
                    "Urkunde Typ",
                ]);
                setCsvRows(
                    groupedAndSlicedItems.flatMap((x) => {
                        const formattedGenders = Object.entries(x.genders)
                            .filter(([, zValue]) => zValue)
                            .map(([zKey]) => formatGender(zKey))
                            .join(", ");
                        const formattedAgeGroups = Object.entries(x.ageGroups)
                            .filter(([, zValue]) => zValue)
                            .map(([zKey]) => zKey)
                            .join(", ");
                        if (x.data.length === 0) {
                            return {
                                Geschlecht: formattedGenders,
                                Altersgruppe: formattedAgeGroups,
                                Rang: undefined,
                                ...participantConvert.toMockupCsvRow(selectedEvent.format, {
                                    isGenderAndBirthYearIncluded: false,
                                    prefix: "Teilnehmer",
                                }),
                                ...certConvert.toMockupCsvRow(selectedEvent.format, {
                                    isParticipantIncluded: false,
                                    prefix: "Urkunde",
                                }),
                            };
                        }
                        return x.data.map((y, yIndex) => ({
                            Geschlecht: formattedGenders,
                            Altersgruppe: formattedAgeGroups,
                            Rang: yIndex + 1,
                            ...participantConvert.toCsvRow(selectedEvent.format, y.participant, {
                                isGenderAndBirthYearIncluded: false,
                                prefix: "Teilnehmer",
                            }),
                            ...certConvert.toCsvRow(selectedEvent.format, y.extraData, {
                                isParticipantIncluded: false,
                                prefix: "Urkunde",
                            }),
                        }));
                    }),
                );
                break;
            }
            default:
                throw new Error(`Invalid statistics type: ${data.type}`);
        }
    };

    const formatRankLimit = (rankLimit) => {
        if (rankLimit === config.event.alltimeLimits.participant) {
            return "Alle";
        }
        return rankLimit;
    };

    const handleExportingButtonClick = () => {
        downloadCsvFile(
            generateDownloadFileName(`Bestenliste nach ${fullTypeString}`, "csv"),
            Papa.unparse(csvRows),
        );
    };

    useEffect(() => {
        formController.handleSubmit(handleFormSubmit)();
    }, []);

    return (
        <Box display="flex" flexDirection="column" gap={1}>
            <CardCollapse isBigTitle title="Einstellungen">
                <Form controller={formController} onSubmit={handleFormSubmit}>
                    <Timeline
                        sx={{
                            p: 0,
                            [`& .${timelineOppositeContentClasses.root}`]: {
                                flex: "none",
                                paddingLeft: 0,
                                width: 128,
                            },
                        }}
                    >
                        <TimelineItem>
                            <TimelineOppositeContent color="textSecondary">
                                1. Kriterium
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Box alignItems="center" display="flex" mb={4}>
                                    <Box width={192}>
                                        <Select
                                            fieldName="type"
                                            formatItem={formatStatisticsType}
                                            items={getEventFormatObject(
                                                [StatisticsType.CERT],
                                                Object.values(StatisticsType),
                                                selectedEvent.format,
                                            )}
                                            label="Kriterium"
                                        />
                                    </Box>
                                    {(typeFieldValue === StatisticsType.DISCIPLINE_CATEGORY ||
                                        typeFieldValue === StatisticsType.DISCIPLINE) && (
                                        <>
                                            <Box ml={2}>
                                                <ArrowForwardIcon />
                                            </Box>
                                            <Box ml={2} width={192}>
                                                {/* TODO Would like to use switch here, but not possible because it thinks it is the same select for a split second and looses the selected value */}
                                                {typeFieldValue ===
                                                    StatisticsType.DISCIPLINE_CATEGORY && (
                                                    <Select
                                                        fieldName="disciplineCategory"
                                                        formatItem={(disciplineCategory) =>
                                                            disciplineCategory.name
                                                        }
                                                        getKeyFromItem={(disciplineCategory) =>
                                                            disciplineCategory.id
                                                        }
                                                        items={
                                                            eventFormatToDisciplineCategoriesMap[
                                                                selectedEvent.format
                                                            ]
                                                        }
                                                        label="Disziplinskategorie"
                                                    />
                                                )}
                                                {typeFieldValue === StatisticsType.DISCIPLINE && (
                                                    <Select
                                                        fieldName="discipline"
                                                        formatItem={(discipline) => discipline.name}
                                                        getKeyFromItem={(discipline) =>
                                                            discipline.id
                                                        }
                                                        items={
                                                            eventFormatToAllDisciplinesMap[
                                                                selectedEvent.format
                                                            ]
                                                        }
                                                        label="Disziplin"
                                                    />
                                                )}
                                            </Box>
                                        </>
                                    )}
                                </Box>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent color="textSecondary">
                                2. Filter
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Box display="flex" gap={4} mb={4}>
                                    <Box>
                                        <FormLabel>Geschlechter</FormLabel>
                                        {Object.values(Gender).map((x) => (
                                            <Box key={x}>
                                                <Checkbox
                                                    fieldName={`genders.${x}`}
                                                    isFullWidth={false}
                                                    label={formatGender(x)}
                                                />
                                            </Box>
                                        ))}
                                    </Box>
                                    <Box>
                                        <FormLabel>Altersgruppen</FormLabel>
                                        <Box display="flex" flexWrap="wrap">
                                            {eventFormatToAgeGroupsMap[selectedEvent.format].map(
                                                (x) => (
                                                    <Checkbox
                                                        key={x}
                                                        fieldName={`ageGroups.${x}`}
                                                        isFullWidth={false}
                                                        label={x.toString().padStart(2, "0")}
                                                    />
                                                ),
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent color="textSecondary">
                                3. Anzeige
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Box display="flex" gap={4}>
                                    <Box>
                                        <RadioSelect
                                            fieldName="grouping"
                                            formatItem={formatStatisticsGrouping}
                                            items={Object.values(StatisticsGrouping)}
                                            label="Gruppierung"
                                        />
                                    </Box>
                                    <Box flex="none" width={192}>
                                        <Select
                                            fieldName="rankLimit"
                                            formatItem={formatRankLimit}
                                            items={rankLimitOptions}
                                            label="Ränge pro Gruppe"
                                        />
                                    </Box>
                                </Box>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>
                    <Box mt={4}>
                        <Button
                            disabled={!formController.formState.isDirty}
                            fullWidth
                            size="large"
                            startIcon={<CalculateIcon />}
                            type="submit"
                            variant="contained"
                        >
                            Generieren
                        </Button>
                    </Box>
                </Form>
            </CardCollapse>
            {csvRows !== undefined && csvRows.length > 0 && (
                <Card>
                    <CardContent>
                        <Box sx={{ position: "relative" }}>
                            <Typography variant="h6">Bestenliste nach {fullTypeString}</Typography>
                            <Button
                                onClick={handleExportingButtonClick}
                                startIcon={<DownloadIcon />}
                                sx={{
                                    position: "absolute",
                                    right: 0,
                                    top: 0,
                                }}
                                variant="outlined"
                            >
                                Exportieren
                            </Button>
                            <Box mt={2}>
                                <CsvDataTable
                                    csvRows={csvRows}
                                    headerColumnCount={2}
                                    isSimplified
                                    visibleColumns={visibleCsvColumns}
                                />
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export { StatisticsPage__LeaderboardTab };
