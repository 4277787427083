import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    FileUpload as FileUploadIcon,
    FormatListNumbered as FormatListNumberedIcon,
    InfoOutlined as InfoOutlinedIcon,
    MoreVert as MoreVertIcon,
    Print as PrintIcon,
} from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    AppErrorType,
    EventFormat,
    Order,
    calculateAgeGroup,
    formatAppErrorType,
    formatClassLevelRange,
    formatGender,
    schoolAppRoutes,
} from "@bujus/common";
import { useApiServiceContext, useDialogDisplayer } from "@bujus/common-frontend";

import { ButtonHotkeyIcon, DataMaster, DoubleIconButton } from "@/base";
import {
    useDashboardTitleStateContext,
    useOpenInstructionsDrawerContext,
    useSelectedEventStateContext,
} from "@/contexts";

import { ManyParticipantsRemovalDialog } from "./many-participants-removal-dialog";
import { ParticipantCreationDialog } from "./participant-creation-dialog";
import { ParticipantRemovalDialog } from "./participant-removal-dialog";
import { ParticipantStarterlistOfAllPrintingDialog } from "./participant-starterlist-printing-dialog";
import { ParticipantUpdateDialog } from "./participant-update-dialog";
import { ParticipantsImportDialog } from "./participants-import-dialog";

const RightDataMasterToolbarComponents = ({
    getKeyFromItem,
    onStarterlistPrintMenuItemClick,
    onCreationButtonClick,
    onImportButtonClick,
    onManyRemovalButtonClick,
    onRemovalButtonClick,
    onUpdateButtonClick,
    query,
    selectedItems,
}) => {
    const openInstructionsDrawer = useOpenInstructionsDrawerContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const menuAnchorElementRef = useRef();

    const handleUpdateButtonClick = (participant) => {
        onUpdateButtonClick(participant);
    };

    const handleRemovalButtonClick = (participant) => {
        onRemovalButtonClick(participant);
    };

    const handleManyRemovalButtonClick = (participants) => {
        onManyRemovalButtonClick(participants);
    };

    const handleCreationButtonClick = () => {
        onCreationButtonClick();
    };

    const handleImportButtonClick = () => {
        onImportButtonClick();
    };

    const handleMenuOpenButtonClick = () => {
        setIsMenuOpen(true);
    };

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };

    const handleStarterlistPrintMenuItemClick = () => {
        onStarterlistPrintMenuItemClick();
    };

    const handleInstructionsButtonClick = () => {
        openInstructionsDrawer("/vorbereitung/teilnehmer-importieren");
    };

    return (
        <>
            {selectedItems.length === 1 && (
                <>
                    <DoubleIconButton
                        disabled={query.isFetching}
                        endIcon={<ButtonHotkeyIcon>E</ButtonHotkeyIcon>}
                        onClick={() =>
                            handleUpdateButtonClick(
                                query.data.items.find(
                                    (x) => getKeyFromItem(x) === selectedItems[0],
                                ),
                            )
                        }
                        startIcon={<EditIcon />}
                    />
                    <DoubleIconButton
                        disabled={query.isFetching}
                        endIcon={<ButtonHotkeyIcon>DEL</ButtonHotkeyIcon>}
                        onClick={() =>
                            handleRemovalButtonClick(
                                query.data.items.find(
                                    (x) => getKeyFromItem(x) === selectedItems[0],
                                ),
                            )
                        }
                        startIcon={<DeleteIcon />}
                    />
                </>
            )}
            {selectedItems.length > 1 && (
                <Button
                    disabled={query.isFetching}
                    endIcon={<ButtonHotkeyIcon>DEL</ButtonHotkeyIcon>}
                    onClick={() =>
                        handleManyRemovalButtonClick(
                            selectedItems.map((x) =>
                                query.data.items.find((y) => getKeyFromItem(y) === x),
                            ),
                        )
                    }
                    startIcon={<DeleteIcon />}
                >
                    {selectedItems.length} entfernen
                </Button>
            )}
            {selectedItems.length > 0 && <Divider flexItem orientation="vertical" />}
            <Tooltip
                title={
                    query.isFetching ||
                    query.data === undefined ||
                    query.data.count < selectedEvent.limits.participant
                        ? ""
                        : formatAppErrorType(AppErrorType.INVALID.PARTICIPANT_LIMIT_REACHED)
                }
            >
                <Box>
                    <Button
                        disabled={
                            query.isFetching ||
                            query.data === undefined ||
                            query.data.count >= selectedEvent.limits.participant
                        }
                        endIcon={<ButtonHotkeyIcon>N</ButtonHotkeyIcon>}
                        onClick={handleCreationButtonClick}
                        startIcon={<AddIcon />}
                    >
                        Hinzufügen
                    </Button>
                </Box>
            </Tooltip>
            <Tooltip
                title={
                    query.isFetching ||
                    query.data === undefined ||
                    query.data.count < selectedEvent.limits.participant
                        ? ""
                        : formatAppErrorType(AppErrorType.INVALID.PARTICIPANT_LIMIT_REACHED)
                }
            >
                <Box>
                    <Button
                        disabled={
                            query.isFetching ||
                            query.data === undefined ||
                            query.data.count >= selectedEvent.limits.participant
                        }
                        onClick={handleImportButtonClick}
                        startIcon={<FileUploadIcon />}
                    >
                        Importieren
                    </Button>
                </Box>
            </Tooltip>
            <IconButton ref={menuAnchorElementRef} onClick={handleMenuOpenButtonClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={menuAnchorElementRef.current}
                onClose={handleMenuClose}
                open={isMenuOpen}
            >
                <MenuItem
                    disabled={
                        query.isFetching || query.data === undefined || query.data.count === 0
                    }
                    onClick={handleStarterlistPrintMenuItemClick}
                >
                    <ListItemIcon>
                        <PrintIcon />
                    </ListItemIcon>
                    <ListItemText>Startliste drucken</ListItemText>
                </MenuItem>
            </Menu>
            <IconButton onClick={handleInstructionsButtonClick}>
                <InfoOutlinedIcon />
            </IconButton>
        </>
    );
};

const ParticipantsPage = () => {
    const navigate = useNavigate();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();
    const [, setDashboardTitle] = useDashboardTitleStateContext();

    const creationDialogDisplayer = useDialogDisplayer(ParticipantCreationDialog);
    const importDialogDisplayer = useDialogDisplayer(ParticipantsImportDialog);
    const starterlistPrintDialogDisplayer = useDialogDisplayer(
        ParticipantStarterlistOfAllPrintingDialog,
    );
    const updateDialogDisplayer = useDialogDisplayer(ParticipantUpdateDialog);
    const removalDialogDisplayer = useDialogDisplayer(ParticipantRemovalDialog);
    const manyRemovalDialogDisplayer = useDialogDisplayer(ManyParticipantsRemovalDialog);
    const dialogDisplayers = [
        creationDialogDisplayer,
        importDialogDisplayer,
        starterlistPrintDialogDisplayer,
        updateDialogDisplayer,
        removalDialogDisplayer,
        manyRemovalDialogDisplayer,
    ];

    const handleResultsShowButtonClick = (participant) => {
        navigate(
            schoolAppRoutes(true)
                .dashboard()
                .results()
                .build({
                    searchValue: [
                        ...(participant.schoolInternalId === undefined
                            ? []
                            : [participant.schoolInternalId]),
                        participant.name.first,
                        participant.name.last,
                    ].join(" "),
                }),
        );
    };

    const handleDataMasterUpdateButtonClick = (participant) => {
        updateDialogDisplayer.open({
            participant,
            removalDialogDisplayer,
        });
    };

    const handleDataMasterRemovalButtonClick = (participant) => {
        removalDialogDisplayer.open({ participant });
    };

    const dataMasterColumns = [
        {
            field: "schoolInternalId",
            type: "string",
            headerName: "Schulinterne-ID",
            width: 150,
        },
        {
            field: "name.first",
            valueGetter: ({ row }) => row.name.first,
            type: "string",
            headerName: "Vorname",
            width: 200,
        },
        {
            field: "name.last",
            valueGetter: ({ row }) => row.name.last,
            type: "string",
            headerName: "Nachname",
            width: 200,
        },
        {
            field: "gender",
            type: "string",
            valueFormatter: ({ value }) => formatGender(value),
            headerName: "Geschlecht",
            width: 100,
        },
        {
            field: "birthYear",
            type: "number",
            valueFormatter: ({ value }) => value.toString(),
            headerName: "Geburtsjahr",
            width: 100,
        },
        {
            field: "ageGroup",
            valueGetter: ({ row }) => calculateAgeGroup(selectedEvent.start, row.birthYear),
            type: "number",
            headerName: "Altersgruppe",
            sortable: false,
            width: 125,
        },
        {
            field: "class",
            type: "string",
            headerName: "Klasse",
            width: 100,
        },
        {
            field: "group.name",
            valueGetter: ({ row }) => (row.group === undefined ? undefined : row.group.name),
            type: "string",
            headerName: "Riege",
            width: 100,
        },
        ...(selectedEvent.format !== EventFormat.CONTEST
            ? []
            : [
                  {
                      field: "group.classLevelRange",
                      valueGetter: ({ row }) =>
                          row.group === undefined
                              ? undefined
                              : formatClassLevelRange(row.group.classLevelRange),
                      type: "string",
                      headerName: "Klassenstufe",
                      width: 100,
                  },
              ]),
        {
            field: "actions",
            getActions: ({ row }) => [
                <Tooltip title="Ergebnisse anzeigen">
                    <GridActionsCellItem
                        icon={<FormatListNumberedIcon />}
                        label="Ergebnisse anzeigen"
                        onClick={() => handleResultsShowButtonClick(row)}
                    />
                </Tooltip>,
                <Tooltip title="Bearbeiten (E)">
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Bearbeiten"
                        onClick={() => handleDataMasterUpdateButtonClick(row)}
                    />
                </Tooltip>,
                <Tooltip title="Entfernen (DEL)">
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Entfernen"
                        onClick={() => handleDataMasterRemovalButtonClick(row)}
                    />
                </Tooltip>,
            ],
            type: "actions",
            headerName: "Aktionen",
            sortable: false,
            width: 125,
        },
    ];

    const handleDataMasterListItemsAsync = async (listingModifiers) =>
        apiService.participants.listAsync(selectedEvent.id, listingModifiers);

    const handleDataMasterCreationHotkeyFire = () => {
        creationDialogDisplayer.open();
    };

    const handleDataMasterRemovalHotkeyFire = (participants) => {
        if (participants.length === 1) {
            removalDialogDisplayer.open({ participant: participants[0] });
        } else {
            manyRemovalDialogDisplayer.open({ participants });
        }
    };

    const handleDataMasterUpdateHotkeyFire = (participant) => {
        updateDialogDisplayer.open({
            participant,
            removalDialogDisplayer,
        });
    };

    const handleDataMasterStarterlistPrintMenuItemClick = () => {
        starterlistPrintDialogDisplayer.open();
    };

    const handleDataMasterCreationButtonClick = () => {
        creationDialogDisplayer.open();
    };

    const handleDataMasterImportButtonClick = () => {
        importDialogDisplayer.open();
    };

    const handleDataMasterManyRemovalButtonClick = (participants) => {
        manyRemovalDialogDisplayer.open({ participants });
    };

    useEffect(() => {
        setDashboardTitle("Teilnehmer");
    }, []);

    return (
        <>
            <Card
                sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                }}
            >
                <CardContent
                    sx={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                    }}
                >
                    <DataMaster
                        baseQueryKey={["participants", "list", selectedEvent.id]}
                        columns={dataMasterColumns}
                        defaultFieldSorting={{
                            path: "name.last",
                            order: Order.ASCENDING,
                        }}
                        dialogDisplayers={dialogDisplayers}
                        exampleSearchDescription="Schulinterne-ID, Vorname, Nachname, ..."
                        fullSearchDescription="Durchsucht Schulinterne-ID, Vorname, Nachname, Geschlecht, Geburtsjahr, Klasse, Riege"
                        getKeyFromItem={(item) => item.id}
                        itemLimit={selectedEvent.limits.participant}
                        listItemsAsync={handleDataMasterListItemsAsync}
                        noFilteredItemsMessage="Keine Teilnehmer gefunden."
                        noItemsMessage="Noch keine Teilnehmer hinzugefügt."
                        onCreationHotkeyFire={handleDataMasterCreationHotkeyFire}
                        onRemovalHotkeyFire={handleDataMasterRemovalHotkeyFire}
                        onUpdateHotkeyFire={handleDataMasterUpdateHotkeyFire}
                        RightToolbarComponents={RightDataMasterToolbarComponents}
                        rightToolbarComponentsProps={{
                            onStarterlistPrintMenuItemClick:
                                handleDataMasterStarterlistPrintMenuItemClick,
                            onCreationButtonClick: handleDataMasterCreationButtonClick,
                            onImportButtonClick: handleDataMasterImportButtonClick,
                            onManyRemovalButtonClick: handleDataMasterManyRemovalButtonClick,
                            onRemovalButtonClick: handleDataMasterRemovalButtonClick,
                            onUpdateButtonClick: handleDataMasterUpdateButtonClick,
                        }}
                    />
                </CardContent>
            </Card>
            {creationDialogDisplayer.element}
            {importDialogDisplayer.element}
            {starterlistPrintDialogDisplayer.element}
            {updateDialogDisplayer.element}
            {removalDialogDisplayer.element}
            {manyRemovalDialogDisplayer.element}
        </>
    );
};

export { ParticipantsPage };
