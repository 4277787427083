import {
    Calculate as CalculateIcon,
    InfoOutlined as InfoOutlinedIcon,
    Visibility as VisibilityIcon,
} from "@mui/icons-material";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import React from "react";

import {
    DisciplineCategoryId,
    EventFormat,
    Order,
    callEventFormatFunction,
    formatCertGenerationErrorType,
    formatClassLevelRange,
    getDisciplineCategory,
} from "@bujus/common";
import { useApiServiceContext, useDialogDisplayer } from "@bujus/common-frontend";

import { DataMaster } from "@/base";
import { useOpenInstructionsDrawerContext, useSelectedEventStateContext } from "@/contexts";
import { getResultColor } from "@/utilities";

import { AllCertsExportingDialog } from "./all-certs-exporting-dialog";
import { AllCertsGenerationDialog } from "./all-certs-generation-dialog";
import { CertDetailsDialog } from "./cert-details-dialog/cert-details-dialog";

const RightDataMasterToolbarComponents = ({ onAllGenerationButtonClick }) => {
    const openInstructionsDrawer = useOpenInstructionsDrawerContext();

    const handleAllGenerationButtonClick = () => {
        onAllGenerationButtonClick();
    };

    const handleInstructionsButtonClick = () => {
        openInstructionsDrawer("/auswertung/urkunden-generieren");
    };

    return (
        <>
            <Button onClick={handleAllGenerationButtonClick} startIcon={<CalculateIcon />}>
                Generieren
            </Button>
            <IconButton onClick={handleInstructionsButtonClick}>
                <InfoOutlinedIcon />
            </IconButton>
        </>
    );
};

const CertsPage__NotGeneratedTab = () => {
    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const allGenerationDialogDisplayer = useDialogDisplayer(AllCertsGenerationDialog);
    const allExportingDialogDisplayer = useDialogDisplayer(AllCertsExportingDialog);
    const detailsDialogDisplayer = useDialogDisplayer(CertDetailsDialog);
    const dialogDisplayers = [
        allGenerationDialogDisplayer,
        allExportingDialogDisplayer,
        detailsDialogDisplayer,
    ];

    const handleDataMasterDetailsButtonClick = (cert) => {
        detailsDialogDisplayer.open({ cert });
    };

    const dataMasterColumns = [
        {
            field: "participant.schoolInternalId",
            valueGetter: ({ row }) => row.participant.schoolInternalId,
            type: "string",
            headerName: "T Schulinterne-ID",
            width: 150,
        },
        {
            field: "participant.name.first",
            valueGetter: ({ row }) => row.participant.name.first,
            type: "string",
            headerName: "Teilnehmer Vorname",
            width: 200,
        },
        {
            field: "participant.name.last",
            valueGetter: ({ row }) => row.participant.name.last,
            type: "string",
            headerName: "Teilnehmer Nachname",
            width: 200,
        },
        {
            field: "participant.class",
            valueGetter: ({ row }) => row.participant.class,
            type: "string",
            headerName: "T Klasse",
            width: 100,
        },
        {
            field: "participant.group.name",
            valueGetter: ({ row }) =>
                row.participant.group === undefined ? undefined : row.participant.group.name,
            type: "string",
            headerName: "T Riege",
            width: 100,
        },
        ...(selectedEvent.format !== EventFormat.CONTEST
            ? []
            : [
                  {
                      field: "participant.group.classLevelRange",
                      valueGetter: ({ row }) =>
                          row.participant.group === undefined
                              ? undefined
                              : row.participant.group.classLevelRange,
                      valueFormatter: ({ value }) =>
                          value === undefined ? undefined : formatClassLevelRange(value),
                      type: "string",
                      headerName: "T Klassenstufe",
                      sortable: false,
                      width: 125,
                  },
              ]),
        {
            field: "errorType",
            valueFormatter: ({ value }) => formatCertGenerationErrorType(value),
            type: "string",
            headerName: "Fehler",
            sortable: false,
            width: 400,
        },
        ...callEventFormatFunction(
            () =>
                Object.values(DisciplineCategoryId).map((x) => ({
                    field: `disciplineCategoryIdToDisciplineCategoryEvaluationMap.${x}.errorType`,
                    type: "number",
                    valueGetter: ({ row }) =>
                        row.disciplineCategoryIdToDisciplineCategoryEvaluationMap[x].errorType,
                    valueFormatter: ({ value }) =>
                        value === undefined ? undefined : formatCertGenerationErrorType(value),
                    headerName: `${getDisciplineCategory(selectedEvent.format, x).name} Fehler`,
                    sortable: false,
                    width: 400,
                })),
            () =>
                Object.values(DisciplineCategoryId).map((x) => ({
                    field: `disciplineCategoryIdToDisciplineCategoryEvaluationMap.${x}.details`,
                    renderCell: ({ row }) =>
                        !row.disciplineCategoryIdToDisciplineCategoryEvaluationMap[x].isValid ? (
                            formatCertGenerationErrorType(
                                row.disciplineCategoryIdToDisciplineCategoryEvaluationMap[x]
                                    .errorType,
                            )
                        ) : (
                            <Typography
                                color={getResultColor(
                                    row.disciplineCategoryIdToDisciplineCategoryEvaluationMap,
                                    row.disciplineCategoryIdToDisciplineCategoryEvaluationMap[x],
                                )}
                            >
                                {
                                    row.disciplineCategoryIdToDisciplineCategoryEvaluationMap[x]
                                        .points
                                }
                            </Typography>
                        ),
                    type: "number",
                    headerName: `${getDisciplineCategory(selectedEvent.format, x).name} Details`,
                    sortable: false,
                    width: 400,
                })),
            selectedEvent.format,
        ),
        {
            field: "actions",
            getActions: ({ row }) => [
                <Tooltip title="Details">
                    <GridActionsCellItem
                        icon={<VisibilityIcon />}
                        label="Details"
                        onClick={() => handleDataMasterDetailsButtonClick(row)}
                    />
                </Tooltip>,
            ],
            type: "actions",
            headerName: "Aktionen",
            sortable: false,
            width: 100,
        },
    ];

    const handleDataMasterListItemsAsync = async (listingModifiers) =>
        apiService.certs.listOnlyNotGeneratedAsync(selectedEvent.id, listingModifiers);

    const handleAllGenerationButtonClick = () => {
        allGenerationDialogDisplayer.open();
    };

    return (
        <>
            <DataMaster
                baseQueryKey={["certs", "list", selectedEvent.id, "isOnlyNotGenerated"]}
                columns={dataMasterColumns}
                defaultFieldSorting={{
                    path: "participant.name.last",
                    order: Order.ASCENDING,
                }}
                dialogDisplayers={dialogDisplayers}
                exampleSearchDescription="Teilnehmer"
                fullSearchDescription="Durchsucht Teilnehmer (Schulinterne-ID, Vorname, Nachname)"
                getKeyFromItem={(item) => item.id}
                listItemsAsync={handleDataMasterListItemsAsync}
                noFilteredItemsMessage="Keine Urkunden gefunden."
                noItemsMessage="Noch keine Urkunden generiert."
                RightToolbarComponents={RightDataMasterToolbarComponents}
                rightToolbarComponentsProps={{
                    onAllGenerationButtonClick: handleAllGenerationButtonClick,
                }}
            />
            {allGenerationDialogDisplayer.element}
            {allExportingDialogDisplayer.element}
            {detailsDialogDisplayer.element}
        </>
    );
};

export { CertsPage__NotGeneratedTab };
