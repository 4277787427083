import { checkIsCountingResult } from "@bujus/common";

const getResultColor = (
    disciplineCategoryIdToDisciplineCategoryEvaluationMap,
    disciplineCategoryEvaluation,
) => {
    if (!disciplineCategoryEvaluation.isValid) {
        return "error.main";
    }
    if (
        !checkIsCountingResult(
            disciplineCategoryIdToDisciplineCategoryEvaluationMap,
            disciplineCategoryEvaluation,
        )
    ) {
        return "error.main";
    }
    return "success.main";
};

export { getResultColor };
